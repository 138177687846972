:root {
  --pink: #f76ca5;
  --blue: #007bff;
  --bs-body-font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
}

.slide-in {
  animation: slide-in 1s ease;
}

@keyframes slide-in {
  from {
    transform: translateX(-30%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

html,
body {
  height: 100%;
}

html {
  font-size: 1rem;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 1.3rem;
  overflow-x: hidden;
}

img {
  width: 100%;
}

header {
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
  padding: 10px 0;
}

.hero {
  background-image: image-set(url("/images/hero.webp") 1x, url("/images/hero.png") 1x);
}

a.post-title {
  font-size: 1.7rem;
}

.post .twitter-tweet {
  margin: auto;
  margin-bottom: 1rem;
}

/* Same as Bootstrap .sr-only */
.post figcaption {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

.post a {
  color: var(--blue);
}

.post a:hover {
  color: var(--pink);
}

.post h2 {
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.post h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.post pre {
  margin-left: calc(50% - 50vw);
  width: 100vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
}

.post pre {
  margin-left: 0;
  width: 100%;
  @apply rounded-md shadow-lg;
}

.post code {
  overflow: auto;
  word-break: break-all;
  font-size: 0.9rem;
}

.post p > code, .post li > code {
  color: var(--pink);
}

.post ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: 1rem;
}

.post ul ul {
  list-style-type: circle;
  margin-bottom: 0;
}

.post ul > li, .post ol > li {
  padding-left: 1em;
}

.post ol {
  list-style-type: decimal;
  list-style-position: inside;
  margin-bottom: 1rem;
}

.post .tweet-this-icon {
  height: 30px;
  margin-bottom: 4px;
}

.post strong {
  font-weight: 600;
}

.post p {
  margin-bottom: 1rem;
}

.post a {
  color: var(--blue);
  text-decoration: underline;
}

.post a:hover {
  color: var(--pink);
}

.post blockquote:before {
  content: "\201C";
  font-size: 5rem;
  position: absolute;
  top: 19px;
  left: -12px;
  font-family: lato;
  color: var(--pink);
}

.post blockquote {
  position: relative;
  padding-left: 2em;
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-style: italic;
  letter-spacing: 0.07rem;
  color: #666;
  font-weight: 300;
}

.post blockquote.pullquote:before {
  content: "";
}

.post blockquote.pullquote {
  border-left: 0.2em solid var(--pink);
  padding-left: 1em;
  color: black;
  font-weight: 400;
  letter-spacing: 0.03rem;
}

@media all and (min-width: 992px) {
  .post blockquote.pullquote{
    padding: 0 20px;
    max-width: 300px;
    float: right;
    margin: 20px -10% 20px 20px;
  }
}

.post .tweet-this-icon {
  height: 30px;
  margin-bottom: 4px;
}

.post hr {
  margin-bottom: 1rem;
}
